import * as React from "react"
import { Container, Box, Text, Section, Flex, Subhead,Heading } from "../ui"
import { StaticImage } from "gatsby-plugin-image"
export default function Meet_our_team(props) {
    return (
        <Section >
        <Container  width="tight" >
            <Flex gap={4} variant="responsive">
                <Box>
                <Heading>Meet Our Team</Heading>
                </Box>
            </Flex>
          
            <Flex gap={4} variant="responsive">
             <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>K. Ram Krishna</Text>
             <Text>CEO</Text>
           </Box>
           <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>K. ShivaPrasad</Text>
             <Text>CTO</Text>
           </Box>
           <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>Umesh CB</Text>
             <Text>CFO</Text>
           </Box>
             </Flex>   
             <Flex gap={4} variant="responsive">
             <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>Radhika .K</Text>
             <Text>Head-College vertical</Text>
           </Box>
           <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>Deepika .K</Text>
             <Text>Head-Corporate vertical</Text>
           </Box>
           <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>Swathi CB</Text>
             <Text>Head-Mentor vertical</Text>
           </Box>
             </Flex>   
             <Flex gap={4} variant="responsive">
            
             <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>Aravindkumar .K</Text>
             <Text>Lead-Graphic design</Text>
           </Box>
           <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>Siva Reddy</Text>
             <Text>Developer</Text>
           </Box>
           <Box style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",borderRadius: "43px"}}>  <StaticImage src='../../images/about_images/team_img.png' alt="team_img"  />
             <Text>Satiya</Text>
             <Text>Developer</Text>
           </Box>
             </Flex>   
         
        </Container>
      </Section>
    )
}