import * as React from "react"
import {
  Container,
  Flex,
  FlexList,
  Space,
  VisuallyHidden,
  NavLink,
} from "../ui"
import {
  platformDesktopHeaderNavWrapper,
  mobileHeaderNavWrapper,
} from "../header.css.ts"
import { StaticImage } from "gatsby-plugin-image"
import { activeNavLink, movileActiveNavLink } from "../../css/platform.css"
import { activeLink } from "../header.css"

export default function AboutNavList(props) {
    const navItems = [{
        id: 1,
        href: "/about",
        text: "About us",
        description: "About us",
        navItemType: '',
        isActive: props.navName === 'about' ? true : false
      },
      {
        id: 2,
        href: "/why_BizLab",
        text: "Why BizLab",
        description: "Why BizLab",
        navItemType: '',
        isActive: props.navName === 'why_BizLab' ? true : false
      },
      {
        id: 3,
        href: "/our_team",
        text: "Our Team",
        description: "Our Team",
        navItemType: '',
        isActive: props.navName === 'our_team' ? true : false
      }]

      return (
        <header>
          <Container width="container" className={platformDesktopHeaderNavWrapper}>
            <div></div>
            <Container  >
              <Space size={2} />
              <Flex variant="spaceBetween">
              <NavLink to="/">
                  <VisuallyHidden>Home</VisuallyHidden>
                  <StaticImage
                    src="../../images/logo.png"
                    width="180"
                    height="35"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="A Gatsby astronaut"
                    style={{ marginBottom: `1.45rem` }}
                  />
                </NavLink>
                <nav style={{ marginBottom: 10 }}>
                  <FlexList gap={4}>
                    {navItems &&
                      navItems.map((navItem) => (
                        <li key={navItem.id}>
                            <NavLink to={navItem.href} > {props.navName === navItem.text ? <span className={activeLink}>{navItem.text}</span> : navItem.text} </NavLink>
                        </li>
                      ))}
                  </FlexList>
                </nav>
              </Flex>
            </Container>
          </Container>
          <Container className={mobileHeaderNavWrapper["closed"]}>
        <Space size={2} />
        <Flex variant="spaceBetween">
        <nav style={{ marginBottom: 10 }} right={3}>
          <FlexList gap={4}>
            {navItems &&
              navItems.map((navItem) => (
                <li key={navItem.id}>
                  <NavLink to={navItem.href} > {props.navName === navItem.text ? <span className={activeLink}>{navItem.text}</span> : navItem.text} </NavLink>
                </li>
              ))}
          </FlexList>
          </nav>
        </Flex>
      </Container>
        </header>
      )
}

