import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Our_team_hero from "../components/our_team/our_tem_hero"
import Meet_our_team from "../components/our_team/meet_our_team"

import AboutNavList from "../components/about/about_nav_items"
const SecondPage = () => (
    <Layout navName="About">
        <Seo title="Our Team" />
        <AboutNavList navName="Our Team" />
        <Our_team_hero/>
       <Meet_our_team/>
        {/* <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link> */}
    </Layout>
)
export default SecondPage
